import finder from '@/module/shared/finder/translations/nl/finder.yaml';
import agGrid from '@/module/shared/general/translations/nl/ag-grid.yaml';
import shared from '@/module/shared/general/translations/nl/shared.yaml';
import validation from '@/module/shared/general/translations/nl/validation.yaml';
import stock from '@/module/core/stock/translations/nl/stock.yaml';
import relation from '@/module/core/relation/translations/nl/relation.yaml';
import employee from '@/module/core/employee/translations/nl/employee.yaml';
import terminal from '@/module/terminal/common/translations/nl/terminal.yaml';
import internalProductionOrder from '@/module/internal-production-order/common/translations/nl/internal-production-order.yaml';
import internalProductionOrderCost from '@/module/internal-production-order/cost/translations/nl/internal-production-order-cost.yaml';
import internalProductionProcess from '@/module/internal-production-order/production-process/translations/nl/internal-production-process.yaml';
import internalProductionOrderProvidedService from '@/module/internal-production-order/provided-service/translations/nl/internal-production-order-provided-service.yaml';
import skreprBrowser from '@/module/skrepr-browser/common/translations/nl/skrepr-browser.yaml';
import expedition from '@/module/expedition/common/translations/nl/expedition.yaml';
import concept from '@/module/sales-order/concept/translations/nl/concept.yaml';

const nl = {
    ...finder,
    ...agGrid,
    ...shared,
    ...validation,
    ...stock,
    ...relation,
    ...employee,
    ...terminal,
    ...internalProductionOrder,
    ...internalProductionOrderCost,
    ...internalProductionProcess,
    ...internalProductionOrderProvidedService,
    ...skreprBrowser,
    ...expedition,
    ...concept
};

export default nl;
